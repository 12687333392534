import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { db } from "../../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import {formatDay} from '../../utils/dateUtils';

const IncomeItem = ({incomeItem, setIncomeItem}) => {
  const { currency, dispatch } = useContext(AppContext);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "income", id));
  };

  const handleEdit = (incomeItem) => {
    setIncomeItem(incomeItem);
    dispatch({ type: 'SET_SHOW_FORM', form: 'incomeEdit' });
  };

  return (
    <li className='movements-item'>
      <div className='date'>{formatDay(incomeItem.date)}</div>
      <div className='name'>{incomeItem.name}</div>
      <div className='value'>{incomeItem.value}{currency}</div>
      <div className='actions'>
        <div className='btn' onClick={() => handleEdit(incomeItem)}>
          <IoMdCreate />
        </div>
        <div className='btn btn' onClick={() => handleDelete(incomeItem.id)}>
          <IoMdTrash />
        </div>
      </div>
     </li>
  );
}

export default IncomeItem;
import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { EmailField, PasswordField } from "../common/Forms";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../utils/firebase';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrors, setLoginErrors] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        //const user = userCredential.user;
        setLoginErrors('');
      })
      .catch((error) => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
        setLoginErrors('Wrong user/password');
      });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      {loginErrors && <div className='form-error'>{loginErrors}</div>}
      <EmailField 
        placeholder="Email" 
        name="email" 
        value={email}
        register={register} 
        required={true} 
        errors={errors} 
        onChange={(e)=>setEmail(e.target.value)} />
      <PasswordField 
        placeholder="Password" 
        name="password" 
        value={password}
        register={register} 
        required={true} 
        errors={errors} 
        onChange={(e)=>setPassword(e.target.value)} />
      <div className="form-submit">
        <button className="btn btn-primary" type="submit">Login</button>
      </div>
    </form>
  );
};

export default LoginForm;
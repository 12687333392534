import React, {useContext, useState, useEffect, useLayoutEffect, useRef} from 'react';
import { AppContext } from '../../contexts/AppContext';
import ExpensesItem from './ExpensesItem';
import {FormModal} from "../common/FormModal";
import ExpensesForm from "../expenses/ExpensesForm";
import {isSelectedMonth} from '../../utils/dateUtils';
import { FormModalOpenBtn } from '../common/FormModal.js';
import { IoMdAdd } from 'react-icons/io';

const ExpensesList = () => {
  const { income, expenses, categories, showForm, selectedMonth } = useContext(AppContext);
  const [expense, setExpense] = useState(null);

  const container = useRef();
  const header = useRef();

  // Sticky header
  useEffect(() => {
    const divAnimate = container.current.getBoundingClientRect().top - 70; // includes header height
  
    const onScroll = () => {
      if (divAnimate < window.scrollY) {
        if(!header.current.classList.contains("fixed")) {
          header.current.classList.add("fixed");
        }
      } else {
        if(header.current.classList.contains("fixed")) {
          header.current.classList.remove("fixed");
        }
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [selectedMonth, income]);

  return (
    <>
      <div className="expenses-list" ref={container}>
        <header className='movements-header' ref={header}>
          <h2>Expenses</h2>
          <FormModalOpenBtn 
            label="Add Expense" 
            formType="expense" 
            className="btn btn-primary add-btn" 
            icon={<IoMdAdd />} />
        </header>
        <ul className='movements-list'>
          {expenses.filter(x => isSelectedMonth(x.date, selectedMonth)).map((expense, index) => (
            <ExpensesItem key={index} expense={expense} category={categories.find(x => x.id === expense.category)} setExpense={setExpense} />
          ))}
        </ul>
      </div>
      {(showForm==='expense') && (<FormModal title="Add Expense">
        <ExpensesForm expense={null} />
      </FormModal>)}
      {(showForm==='expenseEdit') && (<FormModal title="Edit Expense">
        <ExpensesForm expense={expense} />
      </FormModal>)}
    </>
  );
};


export default ExpensesList;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import SettingsBtn from '../settings/SettingsBtn.js';
import LogoutBtn from '../login/LogoutBtn.js';
import MonthNav from './MonthNav.js';

const Header = () => {
  const { showSettings } = useContext(AppContext);

  return (
    <header className={`app-header ${showSettings ? 'settings-opened' : ''}`}>
      <MonthNav />
      <div className='app-header--actions'>
        <SettingsBtn />
        <LogoutBtn />
      </div>
    </header>
  );
};

export default Header;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import CategoriesItem from './CategoriesItem';
import CategoriesForm from './CategoriesForm';

const CategoriesList = () => {
  const { categories, showCategoriesForm } = useContext(AppContext);

  return (
    <div className="categories-list">
      <h2>categories</h2>
      <ul>
        {categories.map((category,index) => (
          <CategoriesItem key={index} category={category} />
        ))}
      </ul>

      {showCategoriesForm === 'add' ? <CategoriesForm /> : <AddCategoryBtn />}
    </div>
  );
};

const AddCategoryBtn = () => {
  const { dispatch } = useContext(AppContext);
  
  const handleClick = () => {
    dispatch({ type: 'SET_SHOW_CATEGORY_FORM', form: 'add' });
  }

  return (
    <button className="btn add-category-btn" onClick={handleClick}>Add Category</button>
  );
}

export default CategoriesList;
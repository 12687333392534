import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { IoMdClose } from 'react-icons/io';

export const FormModal = ({children, title}) => {
  return (
    <div className="form-modal">
      <div className="form-modal--wrapper">
        <header className="form-modal--header">
          <h2>{title}</h2>
          <FormModalCloseBtn className="btn close-modal" icon={<IoMdClose />} />
        </header>
        {children}
      </div>
    </div>
  );
};

export const FormModalOpenBtn = ({label, icon, formType, className}) => {
  const { dispatch } = useContext(AppContext);

  const handleClick = () => {
    dispatch({ type: 'SET_SHOW_FORM', form: formType });
  };

  return (
    <div className={className} onClick={handleClick}>
      {icon && icon} {label && <span className='label'>{label}</span>}
    </div>
  );
};

export const FormModalCloseBtn = ({label, icon, className}) => {
  const { dispatch } = useContext(AppContext);

  const handleClick = () => {
    dispatch({ type: 'SET_SHOW_FORM', form: false });
  };

  return (
    <div className={className} onClick={handleClick}>
      {icon && icon} {label && <span className='label'>{label}</span>}
    </div>
  );
};
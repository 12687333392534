import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { auth } from "./utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Welcome from "./components/welcome/Welcome";
import Header from "./components/common/Header";
import Dashboard from "./components/dashboard/Dashboard";
import { AppProvider } from './contexts/AppContext';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path='/' element={<Welcome />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const ProtectedLayout = () => {
  const [user] = useAuthState(auth);
  if (!user) {
    return <Navigate to="/" />;
  }
  return (
    <AppProvider>
      <Header />
      <Outlet />
    </AppProvider>
  );
};

const PublicLayout = () => {
  const [user] = useAuthState(auth);
  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <><Outlet /></>
  );
};
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { db } from "../../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import CategoriesForm from './CategoriesForm';
import { IoMdCreate, IoMdTrash } from 'react-icons/io';

const CategoriesItem = ({category}) => {
  const { dispatch, showCategoriesForm } = useContext(AppContext);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "categories", id));
  };

  const handleEdit = (id) => {
    dispatch({ type: 'SET_SHOW_CATEGORY_FORM', form: id });
  };

  return (
    <li>
      {showCategoriesForm === category.id ? (      
        <CategoriesForm category={category} />
      ) : (
        <>
          <div className='name'>{category.name}</div>
          <div className='actions'>
            <div className='btn' onClick={() => handleEdit(category.id)}>
              <IoMdCreate />
            </div>
            <div className='btn' onClick={() => handleDelete(category.id)}>
              <IoMdTrash />
            </div>
          </div>
        </>
      )}
    </li>
  );
}

export default CategoriesItem;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import SettingsBtn from "./SettingsBtn";
import CategoriesList from "../categories/CategoriesList";

const SettingsPanel = () => {
  const { showSettings } = useContext(AppContext);

  return (
    <div className={`settings-panel ${showSettings ? 'opened' : ''}`}>
      <header className='settings-header'>
        <h2>Settings</h2>
        <SettingsBtn justCloseIcon={true} />
      </header>
      <CategoriesList />
    </div>
  );
};

export default SettingsPanel;
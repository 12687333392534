import React, {useContext, useState} from 'react';
import { AppContext } from '../../contexts/AppContext';
import {FormModal} from "../common/FormModal";
import IncomeItem from "../income/IncomeItem";
import IncomeForm from "../income/IncomeForm";
import {isSelectedMonth} from '../../utils/dateUtils';
import { FormModalOpenBtn } from '../common/FormModal.js';
import { IoMdAdd } from 'react-icons/io';

const IncomeList = () => {
  const { income, showForm, selectedMonth } = useContext(AppContext);
  const [incomeItem, setIncomeItem] = useState(null);

  return (
    <>
      <div className="income-list">
        <header className='movements-header'>
          <h2>Income</h2>
          <FormModalOpenBtn 
            label="Add Income" 
            formType="income" 
            className="btn btn-primary add-btn" 
            icon={<IoMdAdd />} />
        </header>
        <ul className='movements-list'>
          {income.filter(x => isSelectedMonth(x.date, selectedMonth)).map((item, index) => (
            <IncomeItem key={index} incomeItem={item} setIncomeItem={setIncomeItem} />
          ))}
        </ul>
      </div>
      {(showForm==='income') && (<FormModal title="Add Income">
        <IncomeForm incomeItem={null} />
      </FormModal>)}
      {(showForm==='incomeEdit') && (<FormModal title="Edit Income">
        <IncomeForm incomeItem={incomeItem} />
      </FormModal>)}
    </>
  );
};

export default IncomeList;
import React, {useContext, useState} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useForm } from "react-hook-form";
import { TextField } from "../common/Forms";
import { v4 as uuidv4 } from 'uuid';
import { db } from "../../utils/firebase";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import { IoMdCreate, IoMdAdd, IoMdClose } from 'react-icons/io';

const CategoriesForm = (props) => {
  const [category, setCategory] = useState(() => {
    return {
      name: props.category ? props.category.name : '',
    };
  });
  const { name } = category;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { dispatch } = useContext(AppContext);

  const closeForm = () => {
    dispatch({ type: 'SET_SHOW_CATEGORY_FORM', form: false });
  };

  const handleCancel = () => {
    closeForm();
  };

  const onSubmit = async (data) => {
    closeForm();

    if(props.category) {
      const categoryRef = doc(db, "categories", props.category.id);
      await updateDoc(categoryRef, {
        name: data.name,
      });
    } else {
      const newCategory = {...data, id: uuidv4()};
      await addDoc(collection(db, "categories"), newCategory);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategory((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <form className="categories-form" onSubmit={handleSubmit(onSubmit)}>
      <div className='btn cancel-btn' onClick={handleCancel}><IoMdClose /></div>
      <TextField 
        placeholder="Category name"
        name="name" 
        value={name}
        register={register} 
        required={true} 
        errors={errors} 
        onChange={handleInputChange} />
      <button className="btn add-btn" type="submit">
        {props.expense ? <IoMdCreate /> : <IoMdAdd />} 
      </button>
    </form>
  );
};

export default CategoriesForm;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import {getMinMaxDateInput} from "../../utils/dateUtils";

const RequiredErrorMsg = () => <div className="form-error">This field is required</div>;

export const TextField = ({ label, placeholder, name, value, register, required, errors, onChange }) => {
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      {label && <label>{label}</label>}
      <input 
        {...register(name, { required: required })} 
        placeholder={placeholder ? placeholder : ''}
        type="text"
        name={name} 
        value={value}
        onChange={onChange} />
      {errors[name] && <RequiredErrorMsg />}
    </div>
  );
};

export const FloatField = ({ label, placeholder, name, value, register, required, errors, onChange }) => {
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      {label && <label>{label}</label>}
      <input 
        {...register(name, { required: required, pattern: /[+-]?([0-9]*[.])?[0-9]+/i })} 
        placeholder={placeholder ? placeholder : ''}
        type="number" 
        step="0.0001" 
        name={name} 
        value={value} 
        onChange={onChange} />
      {errors[name] && <RequiredErrorMsg />}
    </div>
  );
};

export const DateField = ({ label, name, value, register, required, errors, onChange }) => {
  const { selectedMonth } = useContext(AppContext);
  const minmax = getMinMaxDateInput(selectedMonth);
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      {label && <label>{label}</label>}
      <input 
        {...register(name, { required: required })}
        type="date" 
        name={name} 
        value={value} 
        onChange={onChange}
        min={minmax.min} max={minmax.max} />
      {errors[name] && <RequiredErrorMsg />}
    </div>
  );
};

export const SelectField = ({ options, emptyOption, label, name, value, register, required, errors, onChange }) => {
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      <label>{label}</label>
      <select 
        defaultValue={value} 
        name={name} 
        {...register(name, { required: required })} 
        onChange={onChange}>
        {emptyOption && <option value="">--</option>}
        {options.map((option,index) => (
          <option 
            key={index} 
            value={option.id}>{option.name}</option>
        ))}
      </select>
      {errors[name] && <RequiredErrorMsg />}
    </div>
  )
};

export const EmailField = ({ label, placeholder, name, value, register, required, errors, onChange }) => {
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      {label && <label>{label}</label>}
      <input 
        {...register(name, { required: required })} 
        placeholder={placeholder ? placeholder : ''}
        type="email"
        name={name} 
        value={value}
        onChange={onChange} />
      {errors[name] && <RequiredErrorMsg />}
    </div>
  );
};

export const PasswordField = ({ label, placeholder, name, value, register, required, errors, onChange }) => {
  return (
    <div className={`form-field ${errors[name] ? "field-error" : ""}`}>
      {label && <label>{label}</label>}
      <input 
        {...register(name, { required: required })} 
        placeholder={placeholder ? placeholder : ''}
        type="password"
        name={name} 
        value={value}
        onChange={onChange} />
      {errors[name] && <RequiredErrorMsg />}
    </div>
  );
};
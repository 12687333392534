import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { prevMonth, nextMonth, currentMonth } from '../../utils/dateUtils';
import {IoIosArrowBack, IoIosArrowForward, IoMdHome} from 'react-icons/io';
import { isAfter, isBefore, endOfYear, addMonths } from 'date-fns';


const MonthNav = () => {
  const { selectedMonth, dispatch } = useContext(AppContext);

  const handleClickPrev = () => {
    const month = prevMonth(selectedMonth);
    const minMonth = new Date('2022-01-01');
    if(isAfter(new Date(selectedMonth), minMonth)) {
      dispatch({ type: 'SET_MONTH', month });
    }
  };
  const handleClickNext = () => {
    const month = nextMonth(selectedMonth);
    const maxMonth = addMonths(endOfYear(new Date()), 1);
    if(isBefore(new Date(selectedMonth), maxMonth)) {
      dispatch({ type: 'SET_MONTH', month });
    }
  };
  const handleClickCurrent = () => {
    const month = currentMonth();
    dispatch({ type: 'SET_MONTH', month });
  };

  return (
    <div className='month-nav'>
      <button className='month-nav--current' onClick={handleClickCurrent}>
        <IoMdHome />
      </button>
      <button className='month-nav--prev' onClick={handleClickPrev}>
        <IoIosArrowBack />
      </button>
      <span className='month-nav--selected'>{selectedMonth}</span>
      <button className='month-nav--next' onClick={handleClickNext}>
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default MonthNav;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { db } from "../../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import {formatDay} from '../../utils/dateUtils';


const ExpensesItem = ({expense, category, setExpense}) => {
  const { currency, dispatch } = useContext(AppContext);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "expenses", id));
  };

  const handleEdit = (expense) => {
    setExpense(expense);
    dispatch({ type: 'SET_SHOW_FORM', form: 'expenseEdit' });
  };
  
  return(
    <li className='movements-item'>
      <div className='date'>{formatDay(expense.date)}</div>
      <div className='name'>{expense.name} {category && <span className='category'>{category.name}</span>}</div>
      <div className='value'>{expense.value}{currency}</div>
      
      <div className='actions'>
        <div className='btn' onClick={() => handleEdit(expense)}>
          <IoMdCreate />
        </div>
        <div className='btn' onClick={() => handleDelete(expense.id)}>
          <IoMdTrash />
        </div>
      </div>
    </li>
  );
};


export default ExpensesItem;
import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import SettingsPanel from "../settings/SettingsPanel";
import ExpensesList from "../expenses/ExpensesList";
import IncomeList from "../income/IncomeList";
import MonthTotals from "../totals/MonthTotals";
import YearTotals from "../totals/YearTotals";

const Dashboard = () => {
  const { showSettings } = useContext(AppContext);

  return (
    <div className={`dashboard ${showSettings ? 'settings-opened' : ''}`}>
      <SettingsPanel />
      <aside className="dashboard-aside">
        <MonthTotals />
        <YearTotals />
      </aside>
      <main className="dashboard-main" role="main">
        <IncomeList />
        <ExpensesList />
      </main>
    </div>
  )
};

export default Dashboard;
import LoginForm from '../login/LoginForm.js';
import { ReactSVG } from 'react-svg';

const Welcome = () => {
  return (
    <main className='welcome-main' role="main">
      <div className='welcome-main--content'>
        <header className='welcome-main--header'>
          <ReactSVG className='logo' src="images/logo.svg" />
          <h1>Login</h1>
        </header>
        
        <LoginForm />
      </div>
    </main>
  );
};

export default Welcome;
import React, {useContext, useState, useEffect} from 'react';
import { AppContext } from '../../contexts/AppContext';
import {isSelectedYear, formatYear} from '../../utils/dateUtils';
import {calculateTotals, calculateSavings} from '../../utils/common';

const YearTotals = () => {
  const { currency, expenses, income, selectedMonth } = useContext(AppContext);
  const [received, setReceived] = useState(0);
  const [waisted, setWaisted] = useState(0);

  useEffect(() => {
    const yearIncome = income.filter(x => isSelectedYear(x.date, selectedMonth));
    if(yearIncome.length) {
      setReceived(yearIncome.reduce((prev, {value}) => calculateTotals(prev,value), 0));
    } else {
      setReceived(0);
    }
  },[income, selectedMonth]);

  useEffect(() => {
    const yearExpenses = expenses.filter(x => isSelectedYear(x.date, selectedMonth));
    if(yearExpenses.length) {
      setWaisted(yearExpenses.reduce((prev, {value}) => calculateTotals(prev,value), 0));
    } else {
      setWaisted(0);
    }
  },[expenses, selectedMonth]);

  return (
    <div className="totals year-totals">
      <h3>{selectedMonth && formatYear(selectedMonth)} Totals</h3>
      <div className='totals-item'>
        <h4 className='title'>Received</h4>
        <div className='description'>{received}{currency}</div>
      </div>
      <div className='totals-item'>
        <h4 className='title'>Waisted</h4>
        <div className='description'>{waisted}{currency}</div>
      </div>
      <div className={`totals-item highlight ${(calculateSavings(received,waisted) <= 0) ? 'negative' : 'positive'}`}>
        <h4 className='title'>Saved</h4>
        <div className='description'>{calculateSavings(received,waisted)}{currency}</div>
      </div>
    </div>
  );
};

export default YearTotals;
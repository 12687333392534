import { auth } from "../../utils/firebase";
import { IoMdLogOut } from 'react-icons/io';

const LogoutBtn = () => {
  const signOut = () => {
    auth.signOut();
  };

  return (
    <button onClick={signOut} className="btn logout-btn" type="button">
      <IoMdLogOut />
    </button>
  );
};

export default LogoutBtn;
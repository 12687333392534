import React, {useContext, useState, useEffect} from 'react';
import { AppContext } from '../../contexts/AppContext';
import {isSelectedMonth, formatMonth} from '../../utils/dateUtils';
import {calculateTotals, calculateSavings} from '../../utils/common';

const MonthTotals = () => {
  const { categories, currency, expenses, income, selectedMonth } = useContext(AppContext);
  const [received, setReceived] = useState(0);
  const [waisted, setWaisted] = useState(0);
  const [waistedByCategory, setWaistedByCategory] = useState(0);

  useEffect(() => {
    const monthIncome = income.filter(x => isSelectedMonth(x.date, selectedMonth));
    if(monthIncome.length) {
      setReceived(monthIncome.reduce((prev, {value}) => calculateTotals(prev,value), 0));
    } else {
      setReceived(0);
    }
  },[income, selectedMonth]);

  useEffect(() => {
    const monthExpenses = expenses.filter(x => isSelectedMonth(x.date, selectedMonth));
    if(monthExpenses.length) {
      setWaisted(monthExpenses.reduce((prev, {value}) => calculateTotals(prev,value), 0));
      setWaistedByCategory(categories.map(category => {
        let totals = monthExpenses.filter(x => x.category === category.id).reduce((prev, {value}) => calculateTotals(prev,value), 0);
        return {...category, totals: totals};
      }));
    } else {
      setWaistedByCategory(categories.map(x => {
        return {...x, totals: 0}
      }));
      setWaisted(0);
    }
  },[expenses, selectedMonth, categories]);

  return (
    <div className="totals month-totals">
      <h3>{selectedMonth && formatMonth(selectedMonth)} Totals</h3>
      <div className='totals-item'>
        <h4 className='title'>Received</h4>
        <div className='description'>{received}{currency}</div>
      </div>
      <div className='totals-item'>
        <h4 className='title'>Waisted</h4>
        <div className='description'>{waisted}{currency}</div>
        <ul className='totals-extras'>
          {waistedByCategory && waistedByCategory.map(category => (
            <li key={category.id}>
              <span className='name'>{category.name}</span> <span className='value'>{category.totals}{currency}</span></li>
          ))}
        </ul>
      </div>
      <div className={`totals-item highlight ${(calculateSavings(received,waisted) <= 0) ? 'negative' : 'positive'}`}>
        <h4 className='title'>Saved</h4>
        <div className='description'>{calculateSavings(received,waisted)}{currency}</div>
      </div>
    </div>
  );
};

export default MonthTotals;
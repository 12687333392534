import React, {useContext} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { IoMdSettings, IoMdClose } from 'react-icons/io';

const SettingsBtn = ({justCloseIcon}) => {
  const { showSettings, dispatch } = useContext(AppContext);

  const handleClick = () => {
    dispatch({ type: 'SHOW_SETTINGS', settings: !showSettings });
  };

  return (
    <button className="btn settings-btn" onClick={handleClick}>
      {justCloseIcon ? <IoMdClose /> : showSettings ? <IoMdClose /> : <IoMdSettings />}
    </button>
  );
};

export default SettingsBtn;
import React, {useContext, useState} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useForm } from "react-hook-form";
import { 
  TextField, 
  FloatField, 
  DateField, 
  SelectField } from "../common/Forms";
import {convertFromInputDate, convertToInputDate} from "../../utils/dateUtils";
import { v4 as uuidv4 } from 'uuid';
import { db } from "../../utils/firebase";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import {FormModalCloseBtn} from '../common/FormModal';

const ExpensesForm = (props) => {
  const { categories } = useContext(AppContext);
  const [expense, setExpense] = useState(() => {
    return {
      name: props.expense ? props.expense.name : '',
      value: props.expense ? props.expense.value : '',
      date: props.expense ? convertToInputDate(props.expense.date) : '',
      category: props.expense ? props.expense.category : '',
    };
  });
  const { name, value, date, category } = expense;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { dispatch } = useContext(AppContext);

  const onSubmit = async (data, event) => {
    const parsedDate = convertFromInputDate(data.date);
    const parsedValue = parseFloat(data.value);

    if(event.nativeEvent.submitter.name !== 'add-more') {
      dispatch({ type: 'SET_SHOW_FORM', form: false });
    }

    if(props.expense) {
      const expenseRef = doc(db, "expenses", props.expense.id);
      await updateDoc(expenseRef, {
        name: data.name,
        value: parsedValue,
        category: data.category,
        date: parsedDate
      });
    } else {
      const newExpense = {...data, id: uuidv4(), date: parsedDate, value: parsedValue};
      if(event.nativeEvent.submitter.name === 'add-more') {
        setExpense({  name: '', value: '', date: '', category: '' });
      }
      await addDoc(collection(db, "expenses"), newExpense);
    }

  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExpense((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DateField 
        label="Date"
        name="date" 
        value={date}
        register={register}
        required={true}  
        errors={errors} 
        onChange={handleInputChange} />

      <TextField 
        label="Name" 
        name="name" 
        value={name}
        register={register} 
        required={true} 
        errors={errors} 
        onChange={handleInputChange} />

      <FloatField 
        label="Value"
        name="value" 
        value={value} 
        register={register}
        required={true}  
        errors={errors} 
        onChange={handleInputChange} />

      <SelectField 
        options={categories} 
        emptyOption = {true}
        label="Category"
        name="category" 
        value={category} 
        register={register}
        required={false}  
        errors={errors} 
        onChange={handleInputChange} />

      <div className="form-submit">
        <FormModalCloseBtn className="btn" label="Cancel" />
        {!props.expense && (
          <button className="btn btn-primary" type="submit" name="add-more">
            Add &amp; Continue
          </button>
        )} 
        <button className="btn btn-primary" type="submit">
          {props.expense ? 'Edit' : 'Add'} 
        </button>
      </div>
    </form>
  )
};

export default ExpensesForm;
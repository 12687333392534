import React, {createContext, useReducer, useEffect} from 'react';
import {
  query,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from '../utils/firebase';
import { currentMonth } from '../utils/dateUtils';

const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        [action.listType]: action.results,
      }
    case 'SET_MONTH': 
      return {
        ...state,
        selectedMonth: action.month,
      }
    case 'SHOW_SETTINGS': 
      return {
        ...state,
        showSettings: action.settings,
      }
    case 'SET_SHOW_FORM':
      return {
        ...state,
        showForm: action.form,
      }
    case 'SET_SHOW_CATEGORY_FORM':
      return {
        ...state,
        showCategoriesForm: action.form,
      }
    default:
      return state;
  }
};

const initialState = {
  selectedMonth: '',
  currency: '€',
  categories: [],
  expenses: [],
  income: [],
  showSettings: false,
  showForm: false,
  showCategoriesForm: false,
};

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Set current month
  useEffect(() => { 
    const month = currentMonth();
    dispatch({ type: 'SET_MONTH', month });
  }, []); 

  // Get data from bd
  useEffect(() => {  
    const q = query(
      collection(db, "categories"),
      orderBy("name"),
    );
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let results = [];
      QuerySnapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: 'SET', listType:'categories', results });
      return () => unsubscribe;
    });
  }, []);
  useEffect(() => {  
    const q = query(
      collection(db, "expenses"),
      orderBy("date"),
    );
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let results = [];
      QuerySnapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: 'SET', listType:'expenses', results });
      return () => unsubscribe;
    });
  }, []);
  useEffect(() => {  
    const q = query(
      collection(db, "income"),
      orderBy("date"),
    );
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let results = [];
      QuerySnapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: 'SET', listType:'income', results });
      return () => unsubscribe;
    });
  }, []);
  

  return (
    <AppContext.Provider
      value={{
        selectedMonth: state.selectedMonth,
        currency: state.currency,
        categories: state.categories,
        expenses: state.expenses,
        income: state.income,
        showSettings: state.showSettings,
        showForm: state.showForm,
        showCategoriesForm: state.showCategoriesForm,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
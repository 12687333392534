import React, {useContext, useState} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useForm } from "react-hook-form";
import { 
  TextField, 
  FloatField, 
  DateField } from "../common/Forms";
import {convertFromInputDate, convertToInputDate} from "../../utils/dateUtils";
import { v4 as uuidv4 } from 'uuid';
import { db } from "../../utils/firebase";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import {FormModalCloseBtn} from '../common/FormModal';

const IncomeForm = (props) => {
  const [incomeItem, setIncomeItem] = useState(() => {
    return {
      name: props.incomeItem ? props.incomeItem.name : '',
      value: props.incomeItem ? props.incomeItem.value : '',
      date: props.incomeItem ? convertToInputDate(props.incomeItem.date) : '',
    };
  });
  const { name, value, date } = incomeItem;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { dispatch } = useContext(AppContext);

  const onSubmit = async (data, event) => {
    const parsedDate = convertFromInputDate(data.date);
    const parsedValue = parseFloat(data.value);

    if(event.nativeEvent.submitter.name !== 'add-more') {
      dispatch({ type: 'SET_SHOW_FORM', form: false });
    }

    if(props.incomeItem) {
      const incomeItemRef = doc(db, "income", props.incomeItem.id);
      await updateDoc(incomeItemRef, {
        name: data.name,
        value: parsedValue,
        date: parsedDate
      });
    } else {
      const newIncomeItem = {...data, id: uuidv4(), date: parsedDate, value: parsedValue};
      if(event.nativeEvent.submitter.name === 'add-more') {
        setIncomeItem({  name: '', value: '', date: '' });
      }
      await addDoc(collection(db, "income"), newIncomeItem);
    }

  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIncomeItem((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DateField 
        label="Date"
        name="date" 
        value={date}
        register={register}
        required={true}  
        errors={errors} 
        onChange={handleInputChange} />

      <TextField 
        label="Name" 
        name="name" 
        value={name}
        register={register} 
        required={true} 
        errors={errors} 
        onChange={handleInputChange} />

      <FloatField 
        label="Value"
        name="value" 
        value={value} 
        register={register}
        required={true}  
        errors={errors} 
        onChange={handleInputChange} />


      <div className="form-submit">
        <FormModalCloseBtn className="btn" label="Cancel" />
        {!props.incomeItem && (
          <button className="btn btn-primary" type="submit" name="add-more">
            Add &amp; Continue
          </button>
        )} 
        <button className="btn btn-primary" type="submit">
          {props.incomeItem ? 'Edit' : 'Add'} 
        </button>
      </div>
    </form>
  )
};

export default IncomeForm;
import { format,subMonths,endOfMonth,isSameMonth,isSameYear } from 'date-fns';

// Format functions
export const currentMonth = () => {
  return format(new Date(), 'MMM yyyy');
};

export const prevMonth = (current) => {
  return format(subMonths(new Date(current), 1), 'MMM yyyy')
};

export const nextMonth = (current) => {
  return format(subMonths(new Date(current), -1), 'MMM yyyy')
};

export const formatDay = (date) => {
  return format(date.toDate(), 'd');
};

export const formatMonth = (date) => {
  return format(new Date(date), 'MMMM');
};

export const formatYear = (date) => {
  return format(new Date(date), 'yyyy');
};


export const monthSlug = (month) => {
  return format(new Date(month), 'MM-yyyy');
};


// Input format functions
export const convertFromInputDate = (date) => {
  return new Date(date);
};

export const convertToInputDate = (date) => {
  return format(date.toDate(), 'yyyy-MM-dd');
};

export const selectedMonthToInput = (month) => {
  return format(new Date(month), 'yyyy-MM');
};

export const getMinMaxDateInput = (month) => {
  const date = new Date(month);
  const lastDay = endOfMonth(date);
  return {
    min: format(date, 'yyyy-MM') + '-01',
    max: format(lastDay, 'yyyy-MM-dd')
  };
};

// Util functions
export const isSelectedMonth = (date,selectedMonth) => {
  return isSameMonth(date.toDate(), new Date(selectedMonth));
};

export const isSelectedYear = (date,selectedMonth) => {
  return isSameYear(date.toDate(), new Date(selectedMonth));
};